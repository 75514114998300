import { EXPERIMENT_NAMES, QUIZ_VARIANTS } from '@/services/constants';

export default {
  name: EXPERIMENT_NAMES.YOGA_28_DAY_CHALLENGE_WEIGHT_GOAL_STEP,
  includedRoutes: ['/tour/yoga-28-day-challenge', '/tour/yoga-28-day-challenge/'],
  variants: [
    {
      tour: QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE,
      path: `/tour/${QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE}`,
    },
    {
      tour: QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_WEIGHT_GOAL_STEP,
      path: `/tour/${QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_WEIGHT_GOAL_STEP}`,
    },
  ],
};
