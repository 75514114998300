const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['authenticateUser'] = require('../middleware/authenticateUser.js')
middleware['authenticateUser'] = middleware['authenticateUser'].default || middleware['authenticateUser']

middleware['detected-country'] = require('../middleware/detected-country.js')
middleware['detected-country'] = middleware['detected-country'].default || middleware['detected-country']

middleware['fix-double-slash'] = require('../middleware/fix-double-slash.js')
middleware['fix-double-slash'] = middleware['fix-double-slash'].default || middleware['fix-double-slash']

middleware['is-quiz-ready'] = require('../middleware/is-quiz-ready.js')
middleware['is-quiz-ready'] = middleware['is-quiz-ready'].default || middleware['is-quiz-ready']

middleware['lead-country-redirect'] = require('../middleware/lead-country-redirect.js')
middleware['lead-country-redirect'] = middleware['lead-country-redirect'].default || middleware['lead-country-redirect']

middleware['persist-quiz-state'] = require('../middleware/persist-quiz-state.js')
middleware['persist-quiz-state'] = middleware['persist-quiz-state'].default || middleware['persist-quiz-state']

export default middleware
