import { getCheckoutIdPathByRegionAndProduct } from '@/modules/checkout/container';
import { EXPERIMENT_NAMES } from '@/services/constants';
import { SET_CHECKOUT_ID } from '../mutation-types';
import { getProductVerticalAndTypeByProduct } from '@/modules/product/container';

export function fetchCheckoutId({ rootState, commit, dispatch }) {
  const { product } = rootState['quiz'];

  const { vertical, type } = getProductVerticalAndTypeByProduct.execute({ product });

  const pricingCheckoutABTest = this.$exp?.activeExperiments.find(({ experiment }) =>
    [EXPERIMENT_NAMES.PRICING_PILATES_WEEKLY_NEW_CHECKOUT].includes(experiment?.name),
  );

  getCheckoutIdPathByRegionAndProduct.execute(
    {
      vertical,
      type,
      pricingModel: product.pricingModel,
      region: this.$countryCode,
      priceVariant: pricingCheckoutABTest?.variants[0]?.pricing || null,
      variation: product?.checkoutVariation || null,
    },
    {
      onSuccess: (checkoutId) => {
        dispatch(
          'logger/logEventWithQuizMeta',
          {
            message: 'New checkout ID found',
            tag: 'new-checkout-id-found',
            meta: {
              checkout: {
                id: checkoutId,
                vertical,
                type,
                pricingModel: product.pricingModel,
                region: this.$countryCode,
                priceVariant: pricingCheckoutABTest?.variants[0]?.pricing || null,
                variation: product?.checkoutVariation || null,
              },
            },
          },
          { root: true },
        );

        commit(SET_CHECKOUT_ID, checkoutId);
      },
      onFailure: () => {
        dispatch(
          'logger/logEventWithQuizMeta',
          {
            message: 'New checkout ID not found',
            tag: 'new-checkout-id-not-found',
            meta: {
              checkout: {
                vertical,
                type,
                id: 'not-found',
                pricingModel: product.pricingModel,
                region: this.$countryCode,
                priceVariant: pricingCheckoutABTest?.variants[0]?.pricing || null,
              },
            },
          },
          { root: true },
        );

        commit(SET_CHECKOUT_ID, null);
      },
    },
  );
}
