/**
 * List of global constants
 * @return {Object} that contain all global constants
 */
export const PAYPAL_TYPES = Object.freeze({
  global: 'paypal',
  us: 'paypal_us',
});

export const STICKY = 'sticky';

export const APPLE_PAY_BRAINTREE = 'apple_pay';

export const APPLE_PAY_CHECKOUT = 'apple_pay_checkout';

export const REDIRECT_TO_IVIM_EVENT = 'redirect-to-ivim';

export const UPSELL_TYPES = Object.freeze({
  familyMeal: 'familyMeal',
  fastFoodMeal: 'fastFoodMeal',
  fastFoodMealPilates: 'fastFoodMealPilates',
  sweetTreatsMeal: 'sweetTreatsMeal',
  premiumContent: 'premiumContent',
  alcohol: 'alcohol',
  dayMealPlan: 'dayMealPlan',
  detox: 'detox',
  '12Month': '12Month',
  '12MonthLifetime': '12MonthLifetime',
  yogaAndHealthyBack: 'yogaAndHealthyBack',
});

export const MEASUREMENTS_ATTRIBUTES = Object.freeze({
  imperial: 'imperial',
  metric: 'metric',
});

export const COOKIES = Object.freeze({
  token: 'token',
  lang: 'rhLang',
  userAttrs: 'userAttrs',
  uniquePaymentOptionsPageEvent: 'uniquePaymentOptionsPageEvent',
  uniqueLeadCreatedEvent: 'uniqueLeadCreatedEvent',
  uniqueConfirmationPageEvent: 'uniqueConfirmationPageEvent',
});

export const UPSELLS_STANDALONE_MAP = Object.freeze({
  fastFoodMeal: 'fast-food-meal-standalone',
  alcohol: 'alcohol-standalone',
  dayMealPlan: 'day-meal-plan-standalone',
});

export const UPSELLS_STANDALONE_API_MAP = Object.freeze({
  'fast-food-meal-standalone': 'fast-food',
  'alcohol-standalone': 'alcohol',
  'day-meal-plan-standalone': 'day-meal-plan',
});

export const PLAN_FOURWEEKS = 'fourWeeks';

export const QUIZ_PRODUCT_TYPES = Object.freeze({
  nutritionMenopause: 'nutrition-menopause',
  nutritionKeto: 'nutrition-keto',
  fitnessPilates: 'fitness-pilates',
  nutritionGeneric: 'nutrition-generic',
  medsWeightLossGlp1: 'meds-weight-loss-glp1',
  fitnessYoga: 'fitness-yoga',
  fitnessYogaMenopause: 'fitness-yoga-menopause',
  fitnessSomaticYoga: 'fitness-somatic-yoga',
});

export const PRICING_MODELS = Object.freeze({
  monthly: 'Monthly',
  weekly: 'Weekly',
});

export const DEFAULT_PRODUCT_TYPES = Object.freeze([
  { 0: 'nutrition-menopause', tourId: 0, productType: 'nutrition-menopause', pricingModel: 'Monthly' },
  { 188: 'nutrition-keto', tourId: 188, productType: 'nutrition-keto', pricingModel: 'Weekly' },
  { 164: 'nutrition-menopause', tourId: 164, productType: 'nutrition-menopause', pricingModel: 'Monthly' },
  { 165: 'nutrition-menopause', tourId: 165, productType: 'nutrition-menopause', pricingModel: 'Monthly' },
  { 166: 'nutrition-menopause', tourId: 166, productType: 'nutrition-menopause', pricingModel: 'Monthly' },
  { 167: 'nutrition-menopause', tourId: 167, productType: 'nutrition-menopause', pricingModel: 'Monthly' },
  { 169: 'nutrition-menopause', tourId: 169, productType: 'nutrition-menopause', pricingModel: 'Monthly' },
  { 168: 'nutrition-menopause', tourId: 168, productType: 'nutrition-menopause', pricingModel: 'Monthly' },
  { 171: 'nutrition-menopause', tourId: 171, productType: 'nutrition-menopause', pricingModel: 'Monthly' },
  { 170: 'nutrition-menopause', tourId: 170, productType: 'nutrition-menopause', pricingModel: 'Monthly' },
  { 172: 'nutrition-menopause', tourId: 172, productType: 'nutrition-menopause', pricingModel: 'Monthly' },
  { 140: 'nutrition-menopause', tourId: 140, productType: 'nutrition-menopause', pricingModel: 'Weekly' },
  { 133: 'fitness-pilates', tourId: 133, productType: 'fitness-pilates', pricingModel: 'Weekly' },
  {
    139: 'fitness-pilates',
    tourId: 139,
    productType: 'fitness-pilates',
    pricingModel: 'Weekly',
    checkoutVariation: 'pilates-now',
  },
  { 144: 'fitness-pilates', tourId: 144, productType: 'fitness-pilates', pricingModel: 'Monthly' },
  { 125: 'nutrition-generic', tourId: 125, productType: 'nutrition-generic', pricingModel: 'Monthly' },
  { 142: 'nutrition-menopause', tourId: 142, productType: 'nutrition-menopause', pricingModel: 'Weekly' },
  { 99: 'nutrition-menopause', tourId: 99, productType: 'nutrition-menopause', pricingModel: 'Monthly' },
  { 131: 'fitness-pilates', tourId: 131, productType: 'fitness-pilates', pricingModel: 'Weekly' },
  { 150: 'nutrition-keto', tourId: 150, productType: 'nutrition-keto', pricingModel: 'Monthly' },
  { 145: 'fitness-pilates', tourId: 145, productType: 'fitness-pilates', pricingModel: 'Monthly' },
  { 147: 'fitness-pilates', tourId: 147, productType: 'fitness-pilates', pricingModel: 'Weekly' },
  { 149: 'fitness-pilates', tourId: 149, productType: 'fitness-pilates', pricingModel: 'Weekly' },
  { 148: 'nutrition-menopause', tourId: 148, productType: 'nutrition-menopause', pricingModel: 'Monthly' },
  { 155: 'fitness-pilates', tourId: 155, productType: 'fitness-pilates', pricingModel: 'Monthly' },
  { 156: 'fitness-pilates', tourId: 156, productType: 'fitness-pilates', pricingModel: 'Monthly' },
  { 159: 'nutrition-menopause', tourId: 159, productType: 'nutrition-menopause', pricingModel: 'Weekly' },
  { 160: 'nutrition-menopause', tourId: 160, productType: 'nutrition-menopause', pricingModel: 'Weekly' },
  { 161: 'nutrition-keto', tourId: 161, productType: 'nutrition-keto', pricingModel: 'Weekly' },
  { 162: 'nutrition-keto', tourId: 162, productType: 'nutrition-keto', pricingModel: 'Monthly' },
  { 153: 'meds-weight-loss-glp1', tourId: 153, productType: 'meds-weight-loss-glp1', pricingModel: 'Monthly' },
  { 174: 'fitness-yoga', tourId: 174, productType: 'fitness-yoga', pricingModel: 'Weekly' },
  { 175: 'fitness-yoga', tourId: 175, productType: 'fitness-yoga', pricingModel: 'Weekly' },
  { 176: 'fitness-yoga', tourId: 176, productType: 'fitness-yoga', pricingModel: 'Weekly' },
  { 177: 'fitness-pilates', tourId: 177, productType: 'fitness-pilates', pricingModel: 'Weekly' },
  { 178: 'fitness-pilates', tourId: 178, productType: 'fitness-pilates', pricingModel: 'Weekly' },
  { 179: 'fitness-pilates', tourId: 179, productType: 'fitness-pilates', pricingModel: 'Weekly' },
  { 180: 'fitness-pilates', tourId: 180, productType: 'fitness-pilates', pricingModel: 'Weekly' },
  { 181: 'nutrition-menopause', tourId: 181, productType: 'nutrition-menopause', pricingModel: 'Monthly' },
  { 182: 'nutrition-menopause', tourId: 182, productType: 'nutrition-menopause', pricingModel: 'Monthly' },
  { 183: 'fitness-pilates', tourId: 183, productType: 'fitness-pilates', pricingModel: 'Weekly' },
  { 184: 'fitness-yoga', tourId: 184, productType: 'fitness-yoga', pricingModel: 'Weekly' },
  { 185: 'nutrition-menopause', tourId: 185, productType: 'nutrition-menopause', pricingModel: 'Monthly' },
  { 186: 'nutrition-menopause', tourId: 186, productType: 'nutrition-menopause', pricingModel: 'Monthly' },
  { 187: 'nutrition-menopause', tourId: 187, productType: 'nutrition-menopause', pricingModel: 'Weekly' },
  { 189: 'nutrition-menopause', tourId: 189, productType: 'nutrition-menopause', pricingModel: 'Monthly' },
  { 191: 'nutrition-menopause', tourId: 191, productType: 'nutrition-menopause', pricingModel: 'Monthly' },
  { 194: 'fitness-pilates', tourId: 194, productType: 'fitness-pilates', pricingModel: 'Weekly' },
  { 195: 'fitness-pilates', tourId: 195, productType: 'fitness-pilates', pricingModel: 'Weekly' },
  { 197: 'fitness-pilates', tourId: 197, productType: 'fitness-pilates', pricingModel: 'Weekly' },
  { 203: 'fitness-pilates', tourId: 203, productType: 'fitness-pilates', pricingModel: 'Weekly' },
  { 202: 'fitness-pilates', tourId: 202, productType: 'fitness-pilates', pricingModel: 'Weekly' },
  { 212: 'nutrition-keto', tourId: 212, productType: 'nutrition-keto', pricingModel: 'Weekly' },
  { 207: 'fitness-pilates', tourId: 207, productType: 'fitness-pilates', pricingModel: 'Weekly' },
  { 208: 'fitness-pilates', tourId: 208, productType: 'fitness-pilates', pricingModel: 'Weekly' },
  { 209: 'fitness-pilates', tourId: 209, productType: 'fitness-pilates', pricingModel: 'Weekly' },
  { 210: 'fitness-yoga-menopause', tourId: 210, productType: 'fitness-yoga-menopause', pricingModel: 'Weekly' },
  { 217: 'nutrition-menopause', tourId: 217, productType: 'nutrition-menopause', pricingModel: 'Monthly' },
  { 211: 'fitness-pilates', tourId: 211, productType: 'fitness-pilates', pricingModel: 'Weekly' },
  { 205: 'nutrition-keto', tourId: 205, productType: 'nutrition-keto', pricingModel: 'Monthly' },
  { 218: 'nutrition-menopause', tourId: 217, productType: 'nutrition-menopause', pricingModel: 'Monthly' },
  { 215: 'fitness-yoga', tourId: 215, productType: 'fitness-yoga', pricingModel: 'Weekly' },
  { 216: 'nutrition-menopause', tourId: 216, productType: 'nutrition-menopause', pricingModel: 'Monthly' },
  { 214: 'fitness-pilates', tourId: 214, productType: 'fitness-pilates', pricingModel: 'Weekly' },
  { 219: 'fitness-somatic-yoga', tourId: 219, productType: 'fitness-somatic-yoga', pricingModel: 'Weekly' },
]);

export const QUIZ_VARIANTS = Object.freeze({
  DEFAULT: '0',
  NUTRITION: '99',
  LEAN_BODY: '121',
  TRIAL: '124',
  MEN: '125',
  BEST_BODY: '129',
  TRIAL_GET_BACK_INTO_SHAPE: '130',
  BODY_28_DAY_CHALLENGE: '132',
  BEST_BODY_OVER_40: '134',
  YOUR_BEST_BODY: '137',
  BEST_LEAN_BODY: '138',
  PILATES_NOW: '139',
  BODY_NUTRITION: '142',
  PILATES_MONTHLY: '144',
  PILATES_28_DAY_CHALLENGE_MONTHLY: '145',
  PILATES_NEW_LANDER: '147',
  WEIGHT_LOSS_MONTHLY: '148',
  PILATES_28_DAY_CHALLENGE_NEW_LANDER: '149',
  NUTRITION_KETO: '150',
  NUTRITION_KETO_MEAL: '205',
  MEDS: '153',
  PILATES_28_DAY_CHALLENGE_DISCLAIMER: '155',
  PILATES_LEGAL_DISCLAIMER: '156',
  HEALTH_WEIGHT_LOSS_28_DAY_CHALLENGE: '159',
  HEALTH_NO_SUGAR_CHALLENGE: '160',
  NUTRITION_KETO_WEEKLY: '161',
  NUTRITION_KETO_AGE: '162',
  FAST_WEIGHT_LOSS: '164',
  MACRO_DIET: '165',
  OPTIMAL_WEIGHT: '166',
  WEIGHT_LOSS_OVER_40: '167',
  WEIGHT_LOSS_WITHOUT_FASTING: '168',
  HEALTH_FOOD_IMPACT: '169',
  METABOLIC_RENEWAL: '170',
  CHOLESTEROL_CONTROL: '171',
  WEIGHT_LOSS_SECRET: '172',
  YOGA_28_DAY_CHALLENGE: '175',
  PILATES_NEW_CHECKOUT: '179',
  PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT: '180',
  PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V1: '195',
  PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V2: '197',
  PILATES_28_DAY_CHALLENGE_REMOVE_ATHLETIC_BODY_OPTION: '214',
  NUTRITION_WEEKLY_START: '187',
  NUTRITION_KETO_WEEKLY_AGE: '188',
  PILATES_28_DAY_CHALLENGE_NEW_SECONDARY_TEXT: '194',
  PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES: '203',
  YOGA_MENOPAUSE_CHALLENGE: '210',
  PILATES_28_DAY_CHALLENGE_UPDATE_WOMEN_JOINED_NUMBER: '211',
  NUTRITION_EXPERIMENT_ATHLETIC_BODY_OPTION_V1: '216',
  YOGA_28_DAY_CHALLENGE_WEIGHT_GOAL_STEP: '184',
  QUIZ_IMAGES_PERFORMANCE_EXPERIMENT: '217',
  NUTRITION_KETO_MEAL_WEEKLY: '212',
  RELOAD_MODAL: '218',
  YOGA_28_DAY_CHALLENGE_REMOVE_ATHLETIC_TYPE: '215',
  SOMATIC_YOGA: '219',
});

export const LANDING_PAGE_IDS = Object.freeze({
  DEFAULT: 0,
  ANTI_INFLAMATORY_DIET: 1,
  BEST_BODY: 2,
  BODY_28_DAY_CHALLENGE: 3,
  CHOLESTEROL_CONTROL: 4,
  FAST_WEIGHT_LOSS_FOR_WOMEN: 5,
  LEAN_BODY: 8,
  MACRO_DIET_FOR_WOMEN: 9,
  MEN: 10,
  METABOLIC_RENEWAL_FOR_WOMEN: 12,
  OPTIMAL_WEIGHT_PROGRAM: 13,
  PILATES: 14,
  PILATES_28_DAY_CHALLENGE: 15,
  TRIAL: 16,
  TRIAL_GET_BACK_INTO_SHAPE: 17,
  WEIGHT_LOSS: 19,
  WEIGHT_LOSS_OVER_40: 20,
  WEIGHT_LOSS_SECRET: 21,
  WEIGHT_LOSS_WITHOUT_FASTING: 22,
  WOMENS_HEALTH_FOOD_IMPACT: 24,
  BEST_BODY_OVER_40: 25,
  YOUR_BEST_BODY: 26,
  BEST_LEAN_BODY: 28,
  PILATES_NOW: 29,
  BODY_NUTRITION: 31,
  PILATES_MONTHLY: 32,
  PILATES_28_DAY_CHALLENGE_MONTHLY: 33,
  NUTRITION_KETO: 35,
  MEDS: 38,
  PILATES_28_DAY_CHALLENGE_DISCLAIMER: 40,
  PILATES_LEGAL_DISCLAIMER: 41,
  HEALTH_NO_SUGAR_CHALLENGE: 42,
  HEALTH_WEIGHT_LOSS_28_DAY_CHALLENGE: 43,
  NUTRITION_KETO_WEEKLY: 44,
  NUTRITION_KETO_AGE: 45,
  NUTRITION_WEEKLY_START: 46,
  YOGA_28_DAY_CHAIR_YOGA_CHALLENGE: 47,
  NUTRITION_KETO_WEEKLY_AGE: 48,
  PILATES_28_DAY_CHALLENGE_NEW_SECONDARY_TEXT: 51,
  PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V1: 53,
  PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V2: 54,
  PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES: 54,
  YOGA_MENOPAUSE_CHALLENGE: 62,
  WEBP_QUIZ_EXPERIMENT: 63,
  YOGA_28_DAY_CHALLENGE_WEIGHT_GOAL_STEP: 66,
  NUTRITION_KETO_MEAL_WEEKLY: 64,
  NUTRITION_KETO_MEAL: 65,
  QUIZ_IMAGES_PERFORMANCE_EXPERIMENT: 66,
  RELOAD_MODAL: 67,
  SOMATIC_YOGA: 69,
});

export const REGIONS = Object.freeze({
  us: 'US',
  br: 'BR',
  ca: 'CA',
  uk: 'UK',
  eu: 'EU',
  au: 'AU',
  sa: 'SA',
  nz: 'NZ',
  latam: 'LATAM',
  join: 'JOIN',
});

export const EXPERIMENT_NAMES = Object.freeze({
  APPLE_PAY_CHECKOUT_DOT_COM: 'apple-pay-checkout-dot-com',
  PRICING_PILATES_WEEKLY: 'pricing-pilates-weekly',
  PRICING_BR_EBANX: 'pricing-br-ebanx',
  PILATES_28_DAY_CHALLENGE_NEW_SECONDARY_TEXT: 'pilates-28-day-challenge-new-secondary-text',
  FITNESS_NEW_CHECKOUT_NEW_DESIGN: 'fitness-new-checkout-new-design',
  NUTRITION_NEW_CHECKOUT_NEW_DESIGN: 'nutrition-new-checkout-new-design',
  PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP: 'tour-pilates-28-day-challenge-add-analysis-step',
  PRICING_PILATES_WEEKLY_NEW_CHECKOUT: 'pricing-pilates-weekly-new-checkout',
  NUTRITION_MENOPAUSE_GIFT: 'nutrition-menopause-gift',
  PAYMENT_ERROR_PERSONALIZED_MESSAGES: 'payment-error-personalized-messages',
  APPLE_PAY_BUTTON_TEXT: 'apple-pay-button-text',
  PILATES_28_DAY_CHALLENGE_UPDATE_WOMEN_JOINED_NUMBER: 'pilates-28-day-challenge-update-women-joined-number',
  YOGA_28_DAY_CHALLENGE_WEIGHT_GOAL_STEP: 'yoga-28-day-challenge-weight-goal-step',
  QUIZ_IMAGES_PERFORMANCE: 'quiz-images-performance',
  NUTRITION_EXPERIMENT_ATHLETIC_BODY_OPTION: 'nutrition-experiment-athletic-body-option',
  RELOAD_MODAL: 'reload-modal',
  YOGA_28_DAY_CHALLENGE_REMOVE_ATHLETIC_TYPE: 'yoga-28-day-challenge-remove-athletic-type',
  PILATES_28_DAY_CHALLENGE_REMOVE_ATHLETIC_BODY_OPTION: 'pilates-28-day-challenge-remove-athletic-body-option',
});

export const PAYMENT_OPTIONS_PATH = 'payment-options';

export const STATES_LIST = Object.freeze([
  { label: 'Alabama', value: 'alabama' },
  { label: 'Alaska', value: 'alaska' },
  { label: 'Arizona', value: 'arizona' },
  { label: 'Arkansas', value: 'arkansas' },
  { label: 'California', value: 'california' },
  { label: 'Colorado', value: 'colorado' },
  { label: 'Connecticut', value: 'connecticut' },
  { label: 'Delaware', value: 'delaware' },
  { label: 'Florida', value: 'florida' },
  { label: 'Georgia', value: 'georgia' },
  { label: 'Hawaii', value: 'hawaii' },
  { label: 'Idaho', value: 'idaho' },
  { label: 'Illinois', value: 'illinois' },
  { label: 'Indiana', value: 'indiana' },
  { label: 'Iowa', value: 'iowa' },
  { label: 'Kansas', value: 'kansas' },
  { label: 'Kentucky', value: 'kentucky' },
  { label: 'Louisiana', value: 'louisiana' },
  { label: 'Maine', value: 'maine' },
  { label: 'Maryland', value: 'maryland' },
  { label: 'Massachusetts', value: 'massachusetts' },
  { label: 'Michigan', value: 'michigan' },
  { label: 'Minnesota', value: 'minnesota' },
  { label: 'Mississippi', value: 'mississippi' },
  { label: 'Missouri', value: 'missouri' },
  { label: 'Montana', value: 'montana' },
  { label: 'Nebraska', value: 'nebraska' },
  { label: 'Nevada', value: 'nevada' },
  { label: 'New Hampshire', value: 'new hampshire' },
  { label: 'New Jersey', value: 'new jersey' },
  { label: 'New Mexico', value: 'new mexico' },
  { label: 'New York', value: 'new york' },
  { label: 'North Carolina', value: 'north carolina' },
  { label: 'North Dakota', value: 'north dakota' },
  { label: 'Ohio', value: 'ohio' },
  { label: 'Oklahoma', value: 'oklahoma' },
  { label: 'Oregon', value: 'oregon' },
  { label: 'Pennsylvania', value: 'pennsylvania' },
  { label: 'Rhode Island', value: 'rhode island' },
  { label: 'South Carolina', value: 'south carolina' },
  { label: 'South Dakota', value: 'south dakota' },
  { label: 'Tennessee', value: 'tennessee' },
  { label: 'Texas', value: 'texas' },
  { label: 'Utah', value: 'utah' },
  { label: 'Vermont', value: 'vermont' },
  { label: 'Virginia', value: 'virginia' },
  { label: 'Washington', value: 'washington' },
  { label: 'West Virginia', value: 'west virginia' },
  { label: 'Wisconsin', value: 'wisconsin' },
  { label: 'Wyoming', value: 'wyoming' },
]);

export const RESTRICTED_STATES = Object.freeze(['mississippi']);
