export default {
  home: () => {
    return '/tour';
  },
  homeBestBody: () => {
    return '/tour/best-body';
  },
  homeBestBodyChallenge: () => {
    return '/tour/body-28-day-challenge';
  },
  homePilates: () => {
    return '/tour/pilates';
  },
  homeSomaticYoga: () => {
    return '/tour/somatic-yoga';
  },
  homePilatesNow: () => {
    return '/tour/pilates-now';
  },
  homeNutritionKeto: () => {
    return '/tour/nutrition-keto';
  },
  homeNutritionKetoMeal: () => {
    return '/tour/nutrition-keto-meal';
  },
  homeNutritionKetoWeekly: () => {
    return '/tour/nutrition-keto-weekly';
  },
  homeNutritionKetoAge: () => {
    return '/tour/nutrition-keto-age';
  },
  homeNutritionKetoWeeklyAge: () => {
    return '/tour/nutrition-keto-weekly-age';
  },
  homeNutritionKetoWeeklyMeal: () => {
    return '/tour/nutrition-keto-meal-weekly';
  },
  homePilatesChallenge: () => {
    return '/tour/pilates-28-day-challenge';
  },
  homePilatesChallengeSomatic: () => {
    return '/tour/pilates-somatic-28-day-challenge';
  },
  homeMacroDietForWomen: () => {
    return '/tour/macro-diet-for-women';
  },
  homeBodyNutrition: () => {
    return '/tour/body-nutrition';
  },
  homeNutritionWeeklyStart: () => {
    return '/tour/nutrition-weekly-start';
  },
  homeNutritionMonthlyStart: () => {
    return '/tour/nutrition-monthly-start';
  },
  homeNutrition28DayNoSugarChallenge: () => {
    return '/tour/nutrition-28-day-sugar-challenge';
  },
  homeNutrition28DayWeightLossChallenge: () => {
    return '/tour/nutrition-28-day-wl-challenge';
  },
  homeMeds: () => {
    return '/tour/meds';
  },
  homeYogaChallenge: () => {
    return '/tour/yoga-challenge-28-days';
  },
  homeMenopauseChallenge: () => {
    return '/tour/yoga-menopause-challenge';
  },
  login: () => {
    return '/login';
  },
  tour: () => {
    return '/tour';
  },
  quiz: () => {
    return '/quiz/start';
  },
  manage: () => {
    return '/manage';
  },
  'manage-cancel-subscription': () => {
    return '/manage/cancel-subscription';
  },
  'manage-subscription': () => {
    return '/manage/subscription';
  },
  'manage-your-subscription': () => {
    return '/manage-your-subscription';
  },
  'request-password-reset': () => {
    return '/request-password-reset';
  },
  'our-cause': () => {
    return '/our-cause';
  },
  blog: () => {
    return '/blog';
  },
  reviews: () => {
    return '/reviews';
  },
  contact: () => {
    return '/contact';
  },
  terms: () => {
    return '/terms';
  },
  privacy: () => {
    return '/privacy';
  },
  // Tapptitude URLs
  appLogin: () => {
    return '/manage/app/login';
  },
  appRequestPasswordReset: () => {
    return '/manage/app/request-password-reset';
  },
};
