import Cookies from 'cookie-universal';
import { getLoggerService } from '@/services/logger';
import { getProductVerticalAndTypeByProduct } from '@/modules/product/container';

const cookies = Cookies();
const { logger } = getLoggerService();

function sendLog({ message, tag, vertical, type, quizId, region, uuid, experiments, source, pricingModel, meta }) {
  logger.info(message, {
    tags: [tag],
    meta: {
      ...meta,
      region,
      lead: {
        uuid,
        quizId,
        experiments,
        source,
      },
      checkout: {
        id: '-',
        vertical,
        type,
        pricingModel,
      },
    },
  });
}

export function logEventWithQuizMeta({ rootState }, { message, tag, meta = {} }) {
  const { product } = rootState['quiz'];
  const { utmObject } = rootState['common'];
  const { experiments, uuid } = rootState['have-lead'];
  const { vertical, type } = getProductVerticalAndTypeByProduct.execute({ product });
  const pricingModel = product.pricingModel;
  const quizId = product.tourId;
  const region = this.$countryCode;
  const source = utmObject.utm_source;

  sendLog({ message, tag, vertical, type, quizId, region, meta, uuid, experiments, source, pricingModel });
}

export function logUniqueEventWithQuizMeta({ rootState }, { message, tag, cookieName, meta = {} }) {
  const cookieValue = cookies.get(cookieName);

  if (cookieValue) return;

  const { product } = rootState['quiz'];
  const { utmObject } = rootState['common'];
  const { experiments, uuid } = rootState['have-lead'];
  const { vertical, type } = getProductVerticalAndTypeByProduct.execute({ product });
  const pricingModel = product.pricingModel;
  const quizId = product.tourId;
  const region = this.$countryCode;
  const source = utmObject.utm_source;

  sendLog({ message, tag, vertical, type, quizId, region, uuid, experiments, source, pricingModel, meta });
  cookies.set(cookieName, true);
}
