// Common
export const SET_CANCELLATION_NAVIGATION = 'SET_CANCELLATION_NAVIGATION';
export const FETCH_CANCELLATION_NAVIGATION = 'FETCH_CANCELLATION_NAVIGATION';
export const GET_SUBSCRIPTION_HISTORY = 'GET_SUBSCRIPTION_HISTORY';
export const SET_SUBSCRIPTION_FETCHING = 'SET_SUBSCRIPTION_FETCHING';
export const SET_SUBSCRIPTION_HISTORY_FETCHING = 'SET_SUBSCRIPTION_HISTORY_FETCHING';
export const SET_SHOW_CONTENT_BY_TRAFFIC_SOURCE = 'SET_SHOW_CONTENT_BY_TRAFFIC_SOURCE';
export const SET_REACTIVATE_SUBSCRIPTION_FETCHING = 'SET_REACTIVATE_SUBSCRIPTION_FETCHING';
export const SET_SOURCE_APP = 'SET_SOURCE_APP';
export const SET_UTM_OBJECT = 'SET_UTM_OBJECT';

// Quiz
export const SET_QUIZ_LIST = 'SET_QUIZ_LIST';
export const SET_QUIZ_INDEX = 'SET_QUIZ_INDEX';
export const SET_QUIZ_TOTAL_LENGTH = 'SET_QUIZ_TOTAL_LENGTH';
export const SET_QUIZ_ANSWERS = 'SET_QUIZ_ANSWERS';
export const SET_HEADER_STATUS = 'SET_HEADER_STATUS';
export const SET_USER_ATTRS_EMAIL = 'SET_USER_ATTRS_EMAIL';
export const SET_USER_ATTRS_FINAL_WEIGHT = 'SET_USER_ATTRS_FINAL_WEIGHT';
export const SET_INTERCOM_ID = 'SET_INTERCOM_ID';
export const SET_TARGET_LOSS = 'SET_TARGET_LOSS';
export const SET_QUIZ_VARIANT = 'SET_QUIZ_VARIANT';
export const SET_LANDING_PAGE_ID = 'SET_LANDING_PAGE_ID';
export const SET_QUIZ_REFERRER_LINK = 'SET_QUIZ_REFERRER_LINK';
export const SET_UNITS = 'SET_UNITS';
export const SET_QUIZ_STEP_FROM = 'SET_QUIZ_STEP_FROM';
export const SET_QUIZ_STEP_TO = 'SET_QUIZ_STEP_TO';
export const SET_DEFAULT_STATE_VALUE_LANDING = 'SET_DEFAULT_STATE_VALUE_LANDING';
export const SET_LEAD_USER_REFERRER_LINK = 'SET_LEAD_USER_REFERRER_LINK';
export const SET_PRODUCT_TYPE = 'SET_PRODUCT_TYPE';
export const SET_PRODUCT = 'SET_PRODUCT';

// Payment
export const SET_PAYMENT_TOKEN = 'SET_PAYMENT_TOKEN';
export const SET_PAYMENT_HAS_ACCESS = 'SET_PAYMENT_HAS_ACCESS';
export const SET_PAYMENT_SELECTED = 'SET_PAYMENT_SELECTED';
export const SET_PAYMENT_FIRST_NAME = 'SET_PAYMENT_FIRST_NAME';
export const SET_PAYMENT_LAST_NAME = 'SET_PAYMENT_LAST_NAME';
export const SET_PAYMENT_EMAIL = 'SET_PAYMENT_EMAIL';
export const SET_PAYMENT_PHONE = 'SET_PAYMENT_PHONE';
export const SET_PAYMENT_OFFER = 'SET_PAYMENT_OFFER';
export const SET_PAYMENT_CHECKOUT_RESPONSE = 'SET_PAYMENT_CHECKOUT_RESPONSE';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const SET_PAYMENT_VARIANT = 'SET_PAYMENT_VARIANT';
export const SET_PAYMENT_TRANSACTION_ID = 'SET_PAYMENT_TRANSACTION_ID';
export const SET_PAYMENT_ORDER_ID = 'SET_PAYMENT_ORDER_ID';
export const SET_PAYMENT_CUSTOMER_ID = 'SET_PAYMENT_CUSTOMER_ID';
export const SET_PAYMENT_SUBSCRIPTION_ID = 'SET_PAYMENT_SUBSCRIPTION_ID';
export const SET_PAYMENT_ADDRESS = 'SET_PAYMENT_ADDRESS';
export const SET_PAYMENT_COMPONENT_NAME = 'SET_PAYMENT_COMPONENT_NAME';
export const SET_PAYMENT_HAS_ERROR = 'SET_PAYMENT_HAS_ERROR';
export const SET_PAYMENT_PAYPAL_DETAILS = 'SET_PAYMENT_PAYPAL_DETAILS';
export const SET_PAYMENT_CREATE_USER_PROMISE = 'SET_PAYMENT_CREATE_USER_PROMISE';
export const SET_PAYMENT_DETECTED_COUNTRY = 'SET_PAYMENT_DETECTED_COUNTRY';
export const SET_PAYMENT_LOCATION_ORIGIN = 'SET_PAYMENT_LOCATION_ORIGIN';
export const SET_PAYMENT_CC_DETAILS = 'SET_PAYMENT_CC_DETAILS';
export const SET_PAYMENT_3D_SECURE_REQUIRED = 'SET_PAYMENT_3D_SECURE_REQUIRED';
export const SET_PAYMENT_3D_SECURE_URL = 'SET_PAYMENT_3D_SECURE_URL';
export const SET_PAYMENT_ORDER_SUMMARY_PAID = 'SET_PAYMENT_ORDER_SUMMARY_PAID';
export const SET_PAYMENT_ORDER_SUMMARY_UNPAID = 'SET_PAYMENT_ORDER_SUMMARY_UNPAID';
export const SET_PAYMENT_ORDER_SUMMARY_UNPAID_LIST = 'SET_PAYMENT_ORDER_SUMMARY_UNPAID_LIST';
export const SET_PAYMENT_AFFILIATES = 'SET_PAYMENT_AFFILIATES';
export const SET_PAYMENT_COUPON_CODE = 'SET_PAYMENT_COUPON_CODE';
export const SET_PAYMENT_APPLE_PAY_INSTANCE = 'SET_PAYMENT_APPLE_PAY_INSTANCE';
export const SET_PAYMENT_APPLE_PAY_NONCE = 'SET_PAYMENT_APPLE_PAY_NONCE';
export const SET_PAYMENT_APPLE_WALLET_TOKEN = 'SET_PAYMENT_APPLE_WALLET_TOKEN';
export const SET_PAYMENT_FIELDS_DOCUMENT_ID = 'SET_PAYMENT_FIELDS_DOCUMENT_ID';

// Auth
export const SET_AUTH_LOGIN_EMAIL = 'SET_AUTH_LOGIN_EMAIL';
export const SET_AUTH_COMPONENT_NAME = 'SET_AUTH_COMPONENT_NAME';
export const SET_AUTH_SUGGESTIC_TOKEN = 'SET_AUTH_SUGGESTIC_TOKEN';
export const SET_AUTH_MAGIC_LINK = 'SET_AUTH_MAGIC_LINK';
export const SET_AUTH_MAGIC_LINK_FETCHING = 'SET_AUTH_MAGIC_LINK_FETCHING';
export const SET_AUTH_RESET_PASSWORD_TOKEN = 'SET_AUTH_RESET_PASSWORD_TOKEN';
export const SET_AUTH_RESET_PASSWORD_PASSWORD = 'SET_AUTH_RESET_PASSWORD_PASSWORD';
export const SET_JWT_DATA = 'SET_JWT_DATA';
export const SET_IS_TAPPTITUDE_USER = 'SET_IS_TAPPTITUDE_USER';

// Footer
export const SET_IS_FOOTER_SHOWN = 'SET_IS_FOOTER_SHOWN';

// Upsell
export const SET_UPSELL_ORDERS = 'SET_UPSELL_ORDERS';
export const SET_UPSELL_ADDRESS = 'SET_UPSELL_ADDRESS';
export const SET_UPSELL_UPDATED_ORDERS = 'SET_UPSELL_UPDATED_ORDERS';
export const SET_UPSELL_PAGE_PROPS = 'SET_UPSELL_PAGE_PROPS';
export const SET_UPSELL_PREVIOUS_ORDER_ID = 'SET_UPSELL_PREVIOUS_ORDER_ID';
export const SET_GLOBAL_EVENT_NAME = 'SET_GLOBAL_EVENT_NAME';
export const SET_UPSELL_DOWNSALE = 'SET_UPSELL_DOWNSALE';
export const SET_UPSELL_SUBSCRIPTION = 'SET_UPSELL_SUBSCRIPTION';
export const SET_UPSELL_STANDALONE = 'SET_UPSELL_STANDALONE';
export const SET_UPSELL_STANDALONE_BOUGHT = 'SET_UPSELL_STANDALONE_BOUGHT';
export const SET_UPSELL_YOGA_AND_HEALTHY_BACK = 'SET_UPSELL_YOGA_AND_HEALTHY_BACK';

// Active Subscription
export const SET_ACTIVE_SUBSCRIPTION_STATUS = 'SET_ACTIVE_SUBSCRIPTION_STATUS';

// Lead Status
export const SET_HAS_LEAD_STATUS = 'SET_LEAD_STATUS';
export const SET_LEAD_UUID = 'SET_LEAD_UUID';
export const SET_LEAD_EXPERIMENTS = 'SET_LEAD_EXPERIMENTS';

// Detect Country
export const SET_DETECTED_COUNTRY = 'SET_USER_COUNTRY';
export const SET_CURRENT_DOMAIN_PREFIX = 'SET_CURRENT_DOMAIN_PREFIX';

// Upsell Sequence
export const SET_UPSELL_CURRENT_COMPONENT_NAME = 'SET_UPSELL_CURRENT_COMPONENT_NAME';
export const SET_UPSELL_LIST = 'SET_UPSELL_LIST';
export const SET_UPSELL_CURRENT_INDEX = 'SET_UPSELL_CURRENT_INDEX';
export const SET_UPSELL_PHYSICAL_LIST = 'SET_UPSELL_PHYSICAL_LIST';
export const SET_UPSELL_PHYSICAL_CURRENT_INDEX = 'SET_UPSELL_PHYSICAL_CURRENT_INDEX';

// Phone Validation
export const SET_PHONE_VERIFIED = 'SET_PHONE_VERIFIED';

// Meds
export const SET_MEDS_SHIPPING = 'SET_MEDS_SHIPPING';

// Checkout
export const SET_CHECKOUT_ID = 'SET_CHECKOUT_ID';
