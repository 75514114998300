import { buildUrlParameters } from '@/services/url';

export const getCheckoutPathHandler = () => ({
  execute: async ({ checkoutId, leadUuid, locale, affiliate = null, uiVariant = null }, { onSuccess, onFailure }) => {
    try {
      if (!leadUuid || !checkoutId) {
        onFailure();
        return;
      }

      const urlParams = buildUrlParameters({
        uuid: leadUuid,
        locale: locale.toLowerCase(),
        aff: affiliate,
        ev: uiVariant,
      });

      const completeCheckoutPath = `${checkoutId}/offers?${urlParams}`;

      onSuccess(completeCheckoutPath);
    } catch {
      onFailure();
    }
  },
});
