export const getCheckoutIdByRegionAndProductHandler = (checkoutRepository) => ({
  execute: async (
    { region, vertical, type, pricingModel, priceVariant = 0, variation = null },
    { onSuccess, onFailure },
  ) => {
    try {
      const { checkoutId } = await checkoutRepository.getCheckoutIdByRegionAndProductWithVariant({
        region: region.toUpperCase(),
        vertical: vertical.toLowerCase(),
        type: type.toLowerCase(),
        pricingModel: pricingModel.toLowerCase(),
        variant: priceVariant,
        variation,
      });

      if (!checkoutId) {
        onFailure();
        return;
      }

      onSuccess(checkoutId);
    } catch {
      onFailure();
    }
  },
});
