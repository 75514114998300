import { EXPERIMENT_NAMES } from '@/services/constants';

export default {
  name: EXPERIMENT_NAMES.NUTRITION_MENOPAUSE_GIFT,
  includedRoutes: [
    '/quiz/enter-email-new',
    '/quiz/enter-email-new/',
    '/quiz/authority-related-testimonials-body-nutrition',
    '/quiz/authority-related-testimonials-body-nutrition/',
  ],
  allocateOnClient: true,
  variants: [
    {
      expVariant: null,
    },
    {
      expVariant: '3887-1',
    },
    {
      expVariant: '3887-2',
    },
  ],
};
