import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import Cookies from 'cookie-universal';
import crypto from 'crypto';

import { getUtmObject, setUtmObject } from '../services/utm';
import {
  APPLE_PAY_BRAINTREE,
  APPLE_PAY_CHECKOUT,
  COOKIES,
  EXPERIMENT_NAMES,
  PAYPAL_TYPES,
  QUIZ_VARIANTS,
  REGIONS,
} from '@/services/constants';
import { isEmailBlocked } from '@/services/ignored_email_list_from_GTM';
import { useIntercom } from '@/composables/useIntercom';
import { getNewCheckoutUrlPath } from '@/modules/checkout/container';
import { getLangFromLocale } from '@/services/locale';

const touchMap = new WeakMap();

/**
 * Shared global mixins
 * {keyGenerator} - you give it a string and it will return slugify string
 * {delayTouch} - to delay the error message and show it after 1s from typing
 * {handleNext} - to go to the next question and also handles firebase events
 */
Vue.mixin({
  data() {
    return {
      inputType: 'password',
      iconSymbolName: 'icon-eye-strike',
    };
  },
  computed: {
    ...mapGetters('quiz', [
      'getQuizCurrentIndex',
      'getQuizTotalLength',
      'getQuizAnswerList',
      'getQuizVariant',
      'getQuizList',
      'getAnswerById',
      'getProductType',
      'getQuizReferrerLink',
    ]),
    ...mapGetters('payment', ['getUserEmail', 'getAffiliates']),
    ...mapGetters('detected-country', ['getDetectedCountry', 'isPrefixUkOrEU']),
    ...mapState({
      userSubscription: (state) => state.common.userSubscription,
      leadUuid: (state) => state['have-lead'].uuid,
      newCheckoutIdToRedirect: (state) => state['checkout'].checkoutId,
    }),
    getApplePayType() {
      return [REGIONS.us, REGIONS.latam, REGIONS.br, REGIONS.join].includes(this.$countryCode)
        ? APPLE_PAY_BRAINTREE
        : APPLE_PAY_CHECKOUT;
    },
    getPaypalType() {
      return this.isPrefixUkOrEU ? PAYPAL_TYPES.global : PAYPAL_TYPES.us;
    },
    showNewlogo() {
      const utmObject = this.getUtmObject();
      const isFacebookSource = utmObject?.utm_source === 'facebook' || false;
      const isCampaign15605498778 = utmObject?.utm_campaign === '15605498778' || false;

      return isFacebookSource || isCampaign15605498778 || this.isNewTours;
    },
    getLanguage() {
      const usedLocale = this.$i18n.locales.find((locale) => locale.code === this.$i18n.locale);
      return usedLocale ? usedLocale.acceptedLanguage.substring(0, 2) : this.$i18n.locale;
    },
    // Terms of Service url with language mapping
    tosUrl() {
      return `${this.$getUrl('terms')}?lang=${this.getLanguage}` || this.$getUrl('terms');
    },
    // Privacy Policy url with language mapping
    ppUrl() {
      return `${this.$getUrl('privacy')}?lang=${this.getLanguage}` || this.$getUrl('privacy');
    },
    isPilatesTour() {
      return [
        this.QUIZ_VARIANTS.PILATES_NEW_CHECKOUT,
        this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_SECONDARY_TEXT,
        this.QUIZ_VARIANTS.PILATES_LEGAL_DISCLAIMER,
        this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
        this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_REMOVE_ATHLETIC_BODY_OPTION,
        this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_UPDATE_WOMEN_JOINED_NUMBER,
        this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES,
        this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V1,
        this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V2,
        this.QUIZ_VARIANTS.PILATES_NOW,
      ].includes(this.getQuizVariant);
    },
    isPilatesLegalTour() {
      return [
        this.QUIZ_VARIANTS.PILATES_NEW_CHECKOUT,
        this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
        this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_REMOVE_ATHLETIC_BODY_OPTION,
        this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_UPDATE_WOMEN_JOINED_NUMBER,
        this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES,
        this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V1,
        this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V2,
        this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_SECONDARY_TEXT,
      ].includes(this.getQuizVariant);
    },
    isBodyNutritionTour() {
      return [
        this.QUIZ_VARIANTS.BODY_NUTRITION,
        this.QUIZ_VARIANTS.HEALTH_NO_SUGAR_CHALLENGE,
        this.QUIZ_VARIANTS.HEALTH_WEIGHT_LOSS_28_DAY_CHALLENGE,
      ].includes(this.getQuizVariant);
    },
    isNutritionKetoTour() {
      return [
        QUIZ_VARIANTS.NUTRITION_KETO,
        QUIZ_VARIANTS.NUTRITION_KETO_MEAL_WEEKLY,
        QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY,
        QUIZ_VARIANTS.NUTRITION_KETO_MEAL,
        QUIZ_VARIANTS.NUTRITION_KETO_AGE,
        QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY_AGE,
      ].includes(this.getQuizVariant);
    },
    isKetoMonthlyTour() {
      return [
        this.QUIZ_VARIANTS.NUTRITION_KETO,
        this.QUIZ_VARIANTS.NUTRITION_KETO_MEAL,
        this.QUIZ_VARIANTS.NUTRITION_KETO_AGE,
      ].includes(this.getQuizVariant);
    },
    isKetoWeeklyTour() {
      return [QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY, QUIZ_VARIANTS.NUTRITION_KETO_MEAL_WEEKLY].includes(
        this.getQuizVariant,
      );
    },
    isWeightLossMonthlyTour() {
      return this.getQuizVariant === this.QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY;
    },
    isNutritionWeeklyStartTour() {
      return this.getQuizVariant === this.QUIZ_VARIANTS.NUTRITION_WEEKLY_START;
    },
    isNewTours() {
      return (
        this.isPilatesTour ||
        this.isBodyNutritionTour ||
        this.isWeightLossMonthlyTour ||
        this.isNutritionWeeklyStartTour ||
        this.isNutritionKetoTour ||
        this.shouldReplicateWeightLossMonthlyTour ||
        this.isMedsTour ||
        this.isYogaTour ||
        this.isSomaticYogaTour
      );
    },
    shouldReplicateWeightLossMonthlyTour() {
      return [
        this.QUIZ_VARIANTS.FAST_WEIGHT_LOSS,
        this.QUIZ_VARIANTS.MACRO_DIET,
        this.QUIZ_VARIANTS.OPTIMAL_WEIGHT,
        this.QUIZ_VARIANTS.WEIGHT_LOSS_OVER_40,
        this.QUIZ_VARIANTS.WEIGHT_LOSS_WITHOUT_FASTING,
        this.QUIZ_VARIANTS.HEALTH_FOOD_IMPACT,
        this.QUIZ_VARIANTS.METABOLIC_RENEWAL,
        this.QUIZ_VARIANTS.CHOLESTEROL_CONTROL,
        this.QUIZ_VARIANTS.WEIGHT_LOSS_SECRET,
        this.QUIZ_VARIANTS.NUTRITION_EXPERIMENT_ATHLETIC_BODY_OPTION_V1,
        this.QUIZ_VARIANTS.QUIZ_IMAGES_PERFORMANCE_EXPERIMENT,
        this.QUIZ_VARIANTS.RELOAD_MODAL,
      ].includes(this.getQuizVariant);
    },
    isMenTour() {
      return this.getQuizVariant === this.QUIZ_VARIANTS.MEN;
    },
    applePaySupported() {
      return (
        window.ApplePaySession &&
        window.ApplePaySession?.supportsVersion(3) &&
        window.ApplePaySession?.canMakePayments()
      );
    },
    isMedsTour() {
      return this.getQuizVariant === this.QUIZ_VARIANTS.MEDS;
    },
    isYogaTour() {
      return [
        this.QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE,
        this.QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_REMOVE_ATHLETIC_TYPE,
        this.QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_WEIGHT_GOAL_STEP,
        this.QUIZ_VARIANTS.YOGA_MENOPAUSE_CHALLENGE,
      ].includes(this.getQuizVariant);
    },
    isYogaMenopauseTour() {
      return this.getQuizVariant === this.QUIZ_VARIANTS.YOGA_MENOPAUSE_CHALLENGE;
    },
    isSomaticYogaTour() {
      return this.getQuizVariant === this.QUIZ_VARIANTS.SOMATIC_YOGA;
    },
    shouldRenderWeekly() {
      return (
        this.$t('landingWeekly.country').includes(this.$countryCode) ||
        this.getQuizVariant === this.QUIZ_VARIANTS.MEN ||
        this.isNewTours
      );
    },
    isPilates28DayChallengeDisclaimerExperiment() {
      return this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_DISCLAIMER === this.getQuizVariant;
    },
    isPilatesLegalDisclaimer() {
      return [this.QUIZ_VARIANTS.PILATES_LEGAL_DISCLAIMER, this.QUIZ_VARIANTS.PILATES_NEW_CHECKOUT].includes(
        this.getQuizVariant,
      );
    },
    isPilatesNowWeekly2() {
      return this.QUIZ_VARIANTS.PILATES_NOW_WEEKLY_2 === this.getQuizVariant;
    },
    isPilatesNowTour() {
      return this.getQuizVariant === this.QUIZ_VARIANTS.PILATES_NOW;
    },
    getCurrentBmi() {
      const quizMeasurements = this.getAnswerById('measurements');
      const weight = quizMeasurements?.weight;
      const height = (quizMeasurements?.height || 0) / 100;
      const bmi = weight / (height * height);
      return parseFloat(bmi.toFixed(1));
    },
    shouldShowButtonIcon() {
      const variants = [
        QUIZ_VARIANTS.PILATES_NEW_CHECKOUT,
        QUIZ_VARIANTS.PILATES_LEGAL_DISCLAIMER,
        QUIZ_VARIANTS.PILATES_NOW,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_REMOVE_ATHLETIC_BODY_OPTION,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_UPDATE_WOMEN_JOINED_NUMBER,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_SECONDARY_TEXT,
        QUIZ_VARIANTS.NUTRITION_KETO,
        QUIZ_VARIANTS.NUTRITION_KETO_MEAL,
        QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY,
        QUIZ_VARIANTS.NUTRITION_KETO_AGE,
        QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY_AGE,
        QUIZ_VARIANTS.NUTRITION_KETO_MEAL_WEEKLY,
        QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY,
        QUIZ_VARIANTS.NUTRITION_EXPERIMENT_ATHLETIC_BODY_OPTION_V1,
        QUIZ_VARIANTS.BODY_NUTRITION,
        QUIZ_VARIANTS.FAST_WEIGHT_LOSS,
        QUIZ_VARIANTS.MACRO_DIET,
        QUIZ_VARIANTS.OPTIMAL_WEIGHT,
        QUIZ_VARIANTS.WEIGHT_LOSS_OVER_40,
        QUIZ_VARIANTS.WEIGHT_LOSS_WITHOUT_FASTING,
        QUIZ_VARIANTS.HEALTH_FOOD_IMPACT,
        QUIZ_VARIANTS.METABOLIC_RENEWAL,
        QUIZ_VARIANTS.CHOLESTEROL_CONTROL,
        QUIZ_VARIANTS.WEIGHT_LOSS_SECRET,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V1,
        QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V2,
        QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE,
        QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_REMOVE_ATHLETIC_TYPE,
        QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_WEIGHT_GOAL_STEP,
        QUIZ_VARIANTS.YOGA_MENOPAUSE_CHALLENGE,
        QUIZ_VARIANTS.QUIZ_IMAGES_PERFORMANCE_EXPERIMENT,
      ];
      return variants.includes(this.getQuizVariant);
    },
    isQuizImagesExperiment() {
      return QUIZ_VARIANTS.QUIZ_IMAGES_PERFORMANCE_EXPERIMENT === this.getQuizVariant;
    },
  },

  methods: {
    ...mapActions('payment', ['setComponentName', 'setAffiliates']),
    ...mapActions('footer', ['changeFooterStatusAction']),
    ...mapActions('common', ['setUTMObjectStore']),
    ...mapActions('quiz', ['setQuizCurrentIndexAction', 'setHeaderStatusAction']),
    ...mapActions('have-lead', ['checkIfUserHaveLeadDetails', 'setLeadUUID']),
    ...mapActions('logger', ['logEventWithQuizMeta']),
    keyGenerator(value) {
      return value && this.$slugify(value?.toLowerCase());
    },
    delayTouch($v) {
      $v.$reset();
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v));
      }
      touchMap.set($v, setTimeout($v.$touch, 1000));
    },
    scrollToTop() {
      setTimeout(() => {
        window.scroll({
          top: 1,
          left: 0,
          behavior: 'smooth',
        });
      }, 0);
    },
    scrollToBottom() {
      setTimeout(() => {
        window.scrollTo({
          left: 0,
          top: document.body.scrollHeight || document.documentElement.scrollHeight,
          behavior: 'smooth',
        });
      }, 0);
    },
    handleNext(stepId = '') {
      this.scrollToTop();

      const stepIndex = this.getQuizList.findIndex((quiz) => quiz.id === stepId);
      const eventIndex = stepIndex === -1 ? this.getQuizCurrentIndex + 1 : stepIndex;
      this.stepCompletedEvent(eventIndex);

      this.$nextTick(() => {
        this.setQuizCurrentIndexAction(eventIndex);
      });
    },
    stepCompletedEvent(quizCurrentIndex) {
      const quizId = this.quiz?.id || this.$attrs?.id;
      const quizType = this.quiz?.type || this.$attrs?.type;

      const quizAnswer = this.getQuizAnswerList.find((quiz) => quiz.id === quizId)?.answer;

      const gtmObject = {
        event: 'step_completed',
        step_id: quizId,
        tour_step_number: quizCurrentIndex,
      };

      if (quizType === 'measurement') {
        gtmObject.measurement_unit = this.getUnits ?? this.$t('units');
      }

      if (quizAnswer) {
        gtmObject.answer = quizAnswer;
      }

      this.$gtm.push(gtmObject);
    },
    purchaseGTMHandler({
      event = 'purchase',
      transactionId,
      value,
      currency,
      plan,
      paymentMethod,
      items,
      intercomId,
      user_data,
    }) {
      return this.$gtm.push({
        affiliation: 'subscription',
        event,
        transaction_id: transactionId,
        value,
        currency,
        plan,
        items,
        payment_method: paymentMethod,
        intercom_id: intercomId,
        user_data,
        product_type: this.getProductType || '',
        landing_page_path: this.getQuizReferrerLink || '',
        tour_id: this.getQuizVariant || '',
      });
    },
    paymentDeclineEvent({ method, type, error_message }) {
      this.$gtm.push({
        event: 'payment_declined',
        method,
        type,
        error_message,
      });
    },
    toggleInputTypeHandler() {
      if (this.inputType === 'password') {
        this.inputType = 'text';
        this.iconSymbolName = 'icon-eye';
      } else {
        this.inputType = 'password';
        this.iconSymbolName = 'icon-eye-strike';
      }
    },
    optimizeResponsiveCheckoutPage() {
      // Hide Header
      const header = document.querySelector('.main-header');
      const mainLayout = document.querySelector('.ui-layout__main');

      if (header) header.classList.add('main-header--hidden');
      if (mainLayout) mainLayout.classList.add('ui-layout__main--checkout');

      this.changeFooterStatusAction(false);
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    getTourFromExperiment() {
      const tourExperimentsVariants = this.$exp.activeExperiments.map((e) => e.variants.find((v) => v.tour !== null));
      if (!tourExperimentsVariants.length) {
        return;
      }
      if (tourExperimentsVariants.length > 1 && !process.server) {
        this.$sentry.captureMessage(new Error('Multiple Tour experiments variants active simultanously', 'error'));
        console.warn('Multiple Tour experiments variants active simultanously', tourExperimentsVariants);
      }
      return tourExperimentsVariants[0].tour;
    },
    isEmailBlocked(email) {
      return isEmailBlocked(email);
    },
    queryHandler() {
      // Loop through url query and store only the ones that startsWith {UTM}
      const { query } = this.$route;
      const validParams = Object.keys(query).filter((key) => key.startsWith('utm'));
      const utmArray = validParams.map((el) => ({
        [el]: query[el] && Array.isArray(query[el]) ? query[el][0] : query[el],
      }));

      const utmObject = Object.assign({}, ...utmArray);

      // Only set it to vuex store if there is value in the array
      !!utmArray.length && (this.setUtmObject(utmObject) || this.setUTMObjectStore(utmObject));

      const { utm_medium: utmMedium } = query;

      if (utmMedium === 'cpc') {
        this.setHeaderStatusAction(false);
      }

      if (query.aff) {
        this.setAffiliates(true);
      }
    },
    getUtmObject,
    setUtmObject,
    measurementsHandler(obj) {
      const currentObj = this.getQuizAnswerList.find((el) => el.id === 'measurements');
      return {
        id: 'measurements',
        selectedAttribute: obj.selectedAttribute,
        answer: {
          ...currentObj?.answer,
          ...obj.answer,
        },
      };
    },
    checkTourParamsForGTM(quizVariant, lang) {
      if (!quizVariant) {
        console.warn('Quiz variant not found when event occured');
        this.$sentry.captureMessage(new Error('Quiz variant not found when event occured', 'error'));
      }

      if (!lang) {
        console.warn('Language not found when event occured');
        this.$sentry.captureMessage(new Error('Language not found when event occured', 'error'));
      }
    },
    setupIntercom() {
      if (window.Intercom) {
        const isManagePageOrChildren = this.$route.path.startsWith('/manage');

        if (['/login', '/contact'].includes(this.$route.path) || isManagePageOrChildren) {
          const intercom = useIntercom({
            intercomAppID: this.$config.intercomAppID,
            intercomSecretKey: this.$config.intercomSecretKey,
          });

          const cookies = Cookies();
          const storedUserAttrs = cookies.get(COOKIES.userAttrs);

          const desiredAttrs = isManagePageOrChildren
            ? {
                email: storedUserAttrs?.email || this.userSubscription?.userAttrs?.email,
                userId:
                  storedUserAttrs?.uuid || this.userSubscription?.uuid || this.userSubscription?.userAttrs?.intercomId,
              }
            : { email: undefined, userId: undefined };

          intercom.boot(desiredAttrs);
        }
      }
    },
    showIntercomWidget() {
      const cookies = Cookies();
      const storedUserAttrs = cookies.get(COOKIES.userAttrs);
      const email = storedUserAttrs?.email;
      const userId = storedUserAttrs?.uuid;

      const intercom = useIntercom({
        intercomAppID: this.$config.intercomAppID,
        intercomSecretKey: this.$config.intercomSecretKey,
      });

      if (email && userId) {
        intercom.boot({ email, userId });
      } else {
        intercom.update();
      }
    },
    isLandingPageEventPushed() {
      for (let i = 0; i < window?.dataLayer?.length; i++) {
        const eventData = window?.dataLayer[i];
        if (eventData.event === 'landing_page') {
          return true; // landing_page event found
        }
      }
      return false; // landing_page event not found
    },
    landingPageGtmHandler() {
      this.$nextTick(() => {
        try {
          const usedLocale = this.$i18n.locales.find((locale) => locale.code === this.$i18n.locale);
          const language = usedLocale?.acceptedLanguage.substring(0, 2) || 'en';

          this.checkTourParamsForGTM(this.getQuizVariant, language);

          this.$gtm.push({
            event: 'landing_page',
            landing_page_path: this.removeLastSlash(location.pathname),
            product_type: this.getProductType,
            tour_id: this.getQuizVariant,
            language,
            app_version: this.$config.appVersion,
            region: this.$countryCode,
            host: this.$host,
            has_landing_page: true,
            user_data: {
              address: {
                city: this.$geo?.city,
                postal_code: this.$geo?.postalCode,
                region: this.$geo?.regionCode,
                country: this.$geo?.country,
              },
            },
          });

          this.$gtm.push({
            event: 'geo_data',
            user_data: {
              address: {
                city: this.$geo?.city,
                postal_code: this.$geo?.postalCode,
                region: this.$geo?.regionCode,
                country: this.$geo?.country,
              },
            },
          });

          this.$gtm.push({
            tour_id: this.getQuizVariant,
            app_version: this.$config.appVersion,
            language,
            region: this.$countryCode,
            host: this.$host,
            has_landing_page: this.isLandingPageEventPushed(),
            landing_page_path: this.removeLastSlash(location.pathname),
            user_data: {
              address: {
                city: this.$geo?.city,
                postal_code: this.$geo?.postalCode,
                region: this.$geo?.regionCode,
                country: this.$geo?.country,
              },
            },
          });
        } catch (error) {
          this.$sentry.captureMessage(new Error(`Error in landingPageGtmHandler: ${error}`, 'error'));
        }
      });
    },
    tinyHash(data, outputLength = 6) {
      return crypto.createHmac('sha256', 'tinyHash').update(data).digest('hex').substring(0, outputLength);
    },
    removeLastSlash(str) {
      return str.substring(str.length - 1, str.length) === '/' ? str.substring(0, str.length - 1) : str;
    },

    async navigateToOffersPage() {
      try {
        if (!this.leadUuid) {
          this.$sentry.captureMessage('No lead uuid found when redirecting to new checkout', {
            level: 'info',
            extra: { tourId: this.getQuizVariant },
          });
          this.$router.push('/payment-options');
          return;
        }

        const checkoutABTest = this.$exp?.activeExperiments.find(({ experiment }) =>
          [
            EXPERIMENT_NAMES.APPLE_PAY_BUTTON_TEXT,
            EXPERIMENT_NAMES.FITNESS_PILATES_GOOGLE_PAY,
            EXPERIMENT_NAMES.FITNESS_NEW_CHECKOUT_NEW_DESIGN,
            EXPERIMENT_NAMES.NUTRITION_NEW_CHECKOUT_NEW_DESIGN,
            EXPERIMENT_NAMES.NUTRITION_MENOPAUSE_GIFT,
            EXPERIMENT_NAMES.PAYMENT_ERROR_PERSONALIZED_MESSAGES,
          ].includes(experiment?.name),
        );

        getNewCheckoutUrlPath.execute(
          {
            checkoutId: this.newCheckoutIdToRedirect,
            leadUuid: this.leadUuid,
            locale: getLangFromLocale(this.getLanguage),
            uiVariant: checkoutABTest ? checkoutABTest?.variants[0]?.expVariant : null,
            affiliate: this.getAffiliates ? 1 : null,
          },
          {
            onSuccess: (checkoutPath) => {
              this.logEventWithQuizMeta({
                message: 'Redirect to new checkout',
                tag: 'redirect-new-checkout',
                meta: {
                  path: `${this.$config.newCheckoutURL}/${checkoutPath}`,
                },
              });
              location.replace(`${this.$config.newCheckoutURL}/${checkoutPath}`);
            },
            onFailure: () => {
              this.logEventWithQuizMeta({
                message: 'Redirect to old checkout',
                tag: 'redirect-old-checkout',
              });
              this.$router.push('/payment-options');
            },
          },
        );
      } catch (e) {
        this.$sentry.captureException(e);
        this.$router.push('/payment-options');
      }
    },
  },
});
